
/**
 * @name generateImagesSizes
 * @param {String} imageSrc the link to main image source
 * @return {Object} contain a list of image sizes
 */
export const generateImagesSizes = imageSrc => {
  const pointIndex = imageSrc.lastIndexOf('.');
  const imageSrcRootName = imageSrc.substr(0, pointIndex);
  const imageSrcExtension = imageSrc.substr(pointIndex);
  return {
    '100': `${imageSrcRootName}-100x100${imageSrcExtension}`,
    '150': `${imageSrcRootName}-150x150${imageSrcExtension}`,
    '250': `${imageSrcRootName}-250x250${imageSrcExtension}`,
    '300': `${imageSrcRootName}-300x300${imageSrcExtension}`,
    '350': `${imageSrcRootName}-350x350${imageSrcExtension}`,
  }
}

/**
 * compute the discount off of price
 * @returns {number}
 */
export const getDiscount = (regular_price, price) => {
  if( !regular_price.length ) return 0;
  else if( parseFloat(regular_price) > 0) {
    let difference = parseFloat(regular_price) - parseFloat(price);
    return Math.round(difference * 100 / parseFloat(regular_price));
  } else return 0;
};

/**
 * divide a categories list to a structured array to use them
 * in grid layout
 * @param {Array} categories list of all categories
 * @param {Integer} size represent a size of nested array
 * @return {Array} this array contain array of 4 categorie  dimension[4, n]
 */
 export const formatData = (categories, size) => {
   let formatedCategories = [];
   let medArray = [];

   if(categories.length < size) return [categories];


   categories.forEach( ( category , i) => {
     medArray = [...medArray, category];
     if( (i+1)%size === 0 || categories.length === (i+1) ){
       formatedCategories = [...formatedCategories, medArray]
       medArray = [];
     }

   });

   return formatedCategories;
 }

/**
 * @name getId
 * retreive a product/category or other id from _links
 * data
 * @params {Object} _links contain self item and this
 * contain href item which is a link to this products ...
 * @return {Integer} the id
 */
 export const getId = _links => {
   let { self } = _links;
   return self[0].href.substr(
     self[0].href.lastIndexOf("/")+1
   );
 }

/**
 * capitalize a string
 * @param {String} str string param passed to capitalize
 * @return {String} capitalize string
 */
export const capitalize = str => str.split(' ').map(e=>e.charAt(0).toUpperCase().concat(e.substr(1))).join(' ');
