import api from '../api';

const path = 'store.az-servic.com/';
// const path = '127.0.0.1/gatsby-woo';

const pass = 'Y2tfMWQ3MDViYzIyNzA2YTkyNGFlODUwMmY3OWIxM2E0N2E1ZDEzOGUxOTpjc19lZTk2MzZhOTk2MTA1M2Q4NDUzOTIxY2MwZWUxMmU1OWQzZGU3NWMw';

/**
 * @name clearBasket
 * remove all items inside the basket
 */
export const clearBasket = () => {
  if (typeof window !== `undefined`) localStorage.basket = JSON.stringify([]);
}

/**
 * @name removeFromBasket
 * remove product from panel
 * @param {Integer} index the index of product in panel
 */
export const removeFromBasket = index => {
  if (typeof window !== `undefined`) {
    let basket = JSON.parse(localStorage.basket || []);
    basket = [
      ...basket.slice(0, index),
      ...basket.slice(index + 1),
    ]
    localStorage.basket = JSON.stringify(basket);
  }
}

/**
 * @name getProductsInsideBasket
 * @return {Array} list of line_items
 * @see addToBasket line 14
 */
export const getProductsInsideBasket = () => (typeof window !== `undefined` && localStorage.basket) ? JSON.parse(localStorage.basket) : []

/**
 * @name addToBasket
 * save product in localStorage to prepare it for create new order
 * @param {[Object]} line_items contain product details to order
 * @var {Array} newBasket is new basket value if there is a products inside panel
 */
export const addToBasket = line_items => {
  if (typeof window !== `undefined`) {

    let newBasket;
    if (localStorage.basket && localStorage.basket.length) {
      newBasket = JSON.parse(localStorage.basket);
      newBasket.push(line_items);
      localStorage.basket = JSON.stringify(newBasket);
    } else {
      localStorage.basket = JSON.stringify([line_items]);
    }

  }
}

/**
 * search for product
 */
export const searchProducts = url => {
  let headers = {
    "Authorization": `Basic ${pass}`,
    "Content-Type": 'application/json',
  };
  return api.get(
    url,
    headers
  )
}

/**
 * load a products from backend
 * @note this not tested yet because there not enough products in DB
 * @param {String} url from where fetch product(api path)
 * @var {Object} headers contain some headers send to api
 * @return {Array} if exist after parse json response
 */
export const loadProduct = url => {
  let headers = {
    "Authorization": `Basic ${pass}`,
    "Content-Type": 'application/json',
  };
  return api.get(
    url,
    headers
  )
}

/**
 * @name getLikedProducts
 * retreive a list of products liked by costumer
 * @return {Array} list of products
 */
export const getLikedProducts = () => {
  if (typeof window !== `undefined`)
    return JSON.parse(localStorage.likedProducts || "[]")
  else return "[]"
}

/**
 * add to favorite liste
 * @param {Object} product details of the current product added to favorite liste
 */
export const addToFavoriteList = product => {
  if (typeof window !== `undefined`) {
    let likedProductsList = [];
    let oldList;
    if (localStorage.likedProducts && localStorage.likedProducts.length) {
      oldList = JSON.parse(localStorage.likedProducts);
      let newList = oldList.filter(prod => prod.slug.toString() !== product.slug);
      likedProductsList = [
        ...newList,
        product
      ];
    } else {
      likedProductsList = [product];
    }
    localStorage.likedProducts = JSON.stringify(likedProductsList);
  }
}

/**
 * remove a product from favorite list(liked products)
 * @param {String} slug the slug of product to remove from list
 * @return {Array} new list of favorite products(liked products)
 */
export const removeFromFavoriteList = slug => {
  if (typeof window !== `undefined`) {

    let likedProductsList = JSON.parse(localStorage.likedProducts);
    let newList = likedProductsList.filter(product => product.slug.toString() !== slug);
    localStorage.likedProducts = JSON.stringify(newList);

  }
}

/**
 * check if current product is added to favorite list
 * @param {String} slug product slug
 * @return {Boolean} true if exist otherwise false
 */
export const checkIfProductInFavoriteList = slug => {
  let likedProductsList;
  if (typeof window !== `undefined` && localStorage.likedProducts && localStorage.likedProducts.length) {
    likedProductsList = JSON.parse(localStorage.likedProducts);
    for (var i = 0; i < likedProductsList.length; i++)
      if (likedProductsList[i].slug === slug) return true;
  }
  return false;
}

/**
 * send a request to purchase a product and server answer by JSON response
 * @param {Object} order some details about order
 * like productId, quantity customer infos like first name phone number
 * @return {JSON} response from server if accept to create new order or nope
 */
export const purchaseProduct = order => {
  return api.post(
    // 'https://blog.wellnessqatar.com/api/wp-json/wc/v3/orders',
    `https://${path}/wp-json/wc/v3/orders`,
    order,
    {
      "Authorization": `Basic ${pass}`,
      // blog.wellnessqatar.com "Authorization": "Basic Y2tfMjNjMzgwZDE0MWYyMDlmMjM1ZWNkZTYyMDBmYWFlZGExYzk1NTk5Njpjc182NzVmZjc0NGVkMmU2M2VmZTAxMGJlMzIyMjBhODUwOGVkYjkyZWVj",
      "Content-Type": "application/json",
    }
  )
};

/**
 * product rating action sended bt customer or reviewer of product
 * @param {Object} rateDetails some data about rating like reviewer
 * full name email and rateing include a product id
 * @return {JSON} response from backend if create a reviw or not
 */
export const rateProduct = rateDetails => {
  return api.post(
    `https://${path}/wp-json/wc/v3/products/reviews`,
    rateDetails,
    {
      "Authorization": `Basic ${pass}`,
      "Content-Type": "application/json",
    }
  )
};

/*

export const loginAuth = async ( data ) => {
  let res = await fetch(
    data.apirl + '/wp-json/wp/v2/users/me',
    {
        headers: {"Authorization": "Basic " + btoa(`${data.username}:${data.password}`)},
        method: 'POST',
  });
  try {
    return await res.then(res => res.json());
  } catch (error) {

  }
}

export const postOrder = async (data) => {
  return fetch("https://127.0.0.1/gatsby-woo/wp-json/wc/v3/orders", {
    body: JSON.stringify(data),
    headers: {
      Authorization: "Basic Y2tfNzg5OTg0MmQ5MDc5ZWQ5ZGJiYmUxY2FlODg2YzY2ZmYwYjQwZTMwNzpjc18wOTk4MjNmMDkwZTAxMWMxYTk1MTg1NzdkYmFhMmNmZjk5N2M4Nzgz",
      "Content-Type": "application/json"
    },
    method: "POST"
  });
}
*/
