/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Helmet } from 'react-helmet'
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer";
import "./layout.css"

const Layout = ({ children, navigate, location, product = null, searchKey, onChangeSearchKey, categories, onChange }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <meta name="google-site-verification" content="gXraSn_DoRh18eEwLSUt5CZn5RChYqa7YgJ1moMR6OY" />
          <meta name="robots" content="index,follow" />
          <meta name="referrer" content="unsafe-url" />
          <meta name="facebook-domain-verification" content="27ztgr6dqlym3tp4ht35vdash2f4sx" />
          <script type='text/javascript'>{`
             !function(f,b,e,v,n,t,s)
             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
             n.callMethod.apply(n,arguments):n.queue.push(arguments)};
             if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
             n.queue=[];t=b.createElement(e);t.async=!0;
             t.src=v;s=b.getElementsByTagName(e)[0];
             s.parentNode.insertBefore(t,s)}(window, document,'script',
             'https://connect.facebook.net/en_US/fbevents.js');
             fbq('init', '594026125095598');
             fbq('track', 'PageView');
          `}</script>
          {
            product && (
              <script type='text/javascript'>{`
                  fbq('track', 'ViewContent', {
                    "content_name": "${product.name}",
                    "content_ids": "['${product.sku}_${product.wordpress_id}']",
                    "content_type": "product",
                    "value": ${product.price},
                    "currency": "DZD"
                  });
              `}</script>
            )
          }
          <noscript>{`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=594026125095598&ev=PageView&noscript=1"
            />`}</noscript>
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata.title}
          navigate={navigate}
          location={location}
          categories={categories}
          searchKey={searchKey}
          onChangeSearchKey={onChangeSearchKey}
          onChange={onChange}
        />
        <div>
          <main className='container'>{children}</main>
        </div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
