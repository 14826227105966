/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Logo from '../assets/images/logo.png'

function SEO({ description, lang, meta, keywords, title, link, image = null, price = null }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const url = link ? `${site.siteMetadata.siteUrl}/${link}` : site.siteMetadata.siteUrl;
  const site_name = site.siteMetadata.title;
  //console.log(metaDescription)
  return (
    <>
      <Helmet>
        <meta property='og:url' content={`${url}/`} />
      </Helmet>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        link={[
          {
            rel: 'canonical',
            href: `${url}/`
          }
        ]}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            property: `og:title`,
            content: title,
          },
          {
            name: `description`,
            content: title,
          },
          {
            property: `og:image:secure_url`,
            content: image ? image : `${site.siteMetadata.siteUrl}/${Logo}`,
          },
          {
            property: `og:image:alt`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: `${image ? image : site.siteMetadata.siteUrl + Logo}`,
          },
          {
            property: `og:type`,
            content: `product`,
          },
          {
            property: `og:price:amount`,
            content: `${price ? price : 1000}`,
          },
          {
            property: `og:price:currency`,
            content: `DZD`,
          },
          {
            property: `og:product:availability`,
            content: `instock`,
          },
          {
            property: `og:locale`,
            content: `fr_FR`,
          },
          {
            property: `og:site_name`,
            content: `${site_name}`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
              : []
          )
          .concat(meta)}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
