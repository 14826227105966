import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHome, 
  faBaby, 
  faFemale, 
  faGamepad,
  faMobileAlt,
  faLaptop,
  faCar,
  faTshirt,
  faFireExtinguisher,
  faTv,
  faCampground,
  faHeartbeat
} from '@fortawesome/free-solid-svg-icons'

import '../../assets/css/main-categories-menu.css';

const categoryIcon = {
  'jeux-et-jouet': faGamepad,
  'sante-et-beaute': faHeartbeat,
  'auto-et-accessoires': faCar,
  'camping-et-randonnee': faCampground,
  'informatique': faLaptop,
  'maison-et-jardin':faHome,
  'mode-femme': faFemale,
  'mode-homme': faTshirt,
  'securite-et-protection': faFireExtinguisher,
  'telephones-et-accessoires': faMobileAlt,
  'maternite-et-enfance': faBaby,
  'electromenager': faTv,
} 

export const ListMenu = ({ categories, itemClassName = '', linkClassName }) => {
  return (
    <>
      {
        categories.map(category => (
          <li key={`category-item${category.id}`} className={itemClassName}>
            <Link to={`/category/${category.slug}`} className={linkClassName}>
              <span className="ellipsis">
                <FontAwesomeIcon icon={categoryIcon[category.slug] || faHome} />&nbsp;
                {category.name.toUpperCase()}
              </span>
            </Link>
          </li>
        ))
      }
    </>
  )
};


const MainCategoriesMenu = ({data: categories, ...rest}) => (
  <nav className="main-categories-menu" {...rest}>
    <ul className="main-categories-menu__items-container m0">
      <ListMenu 
        categories={categories} 
        itemClassName='main-categories-menu__item mb0' 
        linkClassName="main-categories-menu__item-link" 
      />
    </ul>
  </nav>
)

export default MainCategoriesMenu;
