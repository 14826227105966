var React = require('react');

var BurgerMenuIcon = ({ onClick }) => (
  <button
    aria-expanded="false"
    aria-pressed="mixed"
    aria-label={`burger-button`}
    className="burger-menu-icon" onClick={onClick}>
    <span className="bar "></span>
    <span className="bar "></span>
    <span className="bar "></span>
    <span className="bar "></span>
  </button>
);

module.exports = BurgerMenuIcon;
