import React from 'react';
import { Link } from 'gatsby';

import likeIcon from '../../assets/images/icons/like.svg';
import deliveryIcon from '../../assets/images/icons/delivery-cart.svg';

export default ({numberOfProductInBasket}) => {
  return (
    <ul className="item right">
      <li>
        <Link to='/panier' style={{position: 'relative'}}>
          <mark className="badge badge-danger basket-product-number">{numberOfProductInBasket}</mark>
          <i className="icon"><img src={deliveryIcon} alt="Produits"/></i>
          <span className='icon-text'>Panier</span>
        </Link>
      </li>
      <li>
        <Link to='/favorite-products'>
          <i className="icon"><img src={likeIcon} alt="Favorites"/></i>
          <span className='icon-text'>Favorites</span>
        </Link>
      </li>
    </ul>
  )
}
