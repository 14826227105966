import React from 'react';

import { getId } from '../../utils/tools';

import searchIcon from '../../assets/images/icons/search.svg';

export default class SearchForm extends React.Component {

  state = {
    searchUrl: '/search',
    search: '',
    category: ''
  }

  /**
   * callback function fired when value of field change
   * and change state value depend on filed name
   * and update as well the searchUrl state used on Link
   * @param {HTMLElement} target the element who fired event
   */
  onChange = (e) => {
    let { target } = e;
    // let searchUrl = `/search?search=${this.state.search}&category=${target.name === 'category'? target.value : this.state.category}`;
    this.setState({
      [target.name]: target.value,
      // searchUrl
    });
    if( 'onChange' in this.props && this.props.onChange ) {
      this.props.onChange(e);
    }
  }

  /**
   * called when submit event triggered
   * then redirect customer to the search page
   * by pushing them to this page and see results
   */
   onSubmit = e => {
     e.preventDefault();
     let { category, search } = this.state;
     let path = `/search?search=${search}`;
     path += parseInt(category, 10) ? '&category='+category : '';
     if( 'onChangeSearchKey' in this.props && this.props.onChangeSearchKey )
       this.props.onChangeSearchKey(search, category);
     this.props.navigate(path);
   }

  render() {
    let { searchKey, categories } = this.props;

    return (
      <form onSubmit={this.onSubmit} className='search-form item left center'>
        <label htmlFor="main-categories-list" className='hidden' aria-label="liste des categories">Choisissez Une categorie</label>
        <select name="category" className="left item" onChange={this.onChange} id="main-categories-list">
          {
            categories.edges.map(
              ({node: category}) =>
                <option key={category.id} value={getId(category._links)} checked>{category.name}</option>)
          }
        </select>
        <button type="submit" className="btn right item" >
          <i className="icon"><img src={searchIcon} alt="Produits"/></i>
          <span className='icon-text'>Chercher</span>
        </button>
        <div className="search-bar-container">
          <label htmlFor="search" className='hidden' aria-label="nom de produit">Nom de Produit</label>
          <input type="text" id="search" className="item" name="search" defaultValue={searchKey || ''} placeholder="Rechercher sur .." onChange={this.onChange}/>
        </div>
      </form>
    )
  }
}
