import React from 'react';
import { Link } from 'gatsby';
import { debounce, } from 'lodash';

class Footer extends React.Component {

  constructor() {
    super();
    this.onScroll = debounce(() => {
      this.lazyLoud();
      this.onFixeMenu();
    }, 150);
  }

  /**
    * check if the current element is in viewport
    * that mean verify if user can see this element
    * @param {HTMLElement} element is the cuurent element
    * @return {Boolean} true if is in otherwise false
  */
  isInViewport = element => {
    // check ig element has getBoundingClientRect function
    if( !('getBoundingClientRect' in element) ) return true;
    let rect = element.getBoundingClientRect();
    return (
      rect.left >= 0 &&
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * lazy load backgroundImage
   */
  lazyLoud = () => {
    let _lazy = document.querySelectorAll('.js-lazy-background');
    /**
     * to fix crashing pages on IE10 use
     * the code snipet below for loop
     * instead of foreach
     */
     for (var i = 0; i < _lazy.length; i++) {
       let bgImg = _lazy[i]
       if( this.isInViewport(bgImg) ) {
         let bg = bgImg.getAttribute("data-background");
         bgImg.style.backgroundImage = `url(${bg})`;
         bgImg.className = bgImg.className.replace('loading-background js-lazy-background', "loaded");
       }
     }
  }

  onFixeMenu = () => {
    let _topMenu = document.querySelector('.js-top-fixed');
    let h = document.documentElement, 
    b = document.body,
    st = 'scrollTop',
    _scrollFromTop = (h[st]||b[st]);
    // compute the scroll of page
    if(_scrollFromTop > 120) _topMenu.classList.add('fixed-menu__top');
    else _topMenu.classList.remove('fixed-menu__top'); 
  }
  /**
   * fix a lazy loading of some background on Card
   * that because when costumer change page it not
   * load the images any one untill the backgrounds
   * in viewport
   */
  componentDidUpdate(prevState){
    if( Object.keys(prevState).length === Object.keys(this.props).length)
      this.lazyLoud()
  }

  /**
   * remove all event listener for better performance
   */
  componentWillUnmount() {
    if( typeof window !== `undefined` ) {
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  /**
   * listen to scroll event to avoit lose loading of images
   */
  componentDidMount() {
      if( typeof window !== `undefined` ) {
        window.addEventListener('scroll', this.onScroll);
        this.lazyLoud();
      }
    }

  render() {
    return (
      <footer className='main-footer typography-font'>
        <a href="#top" className='block row center typography-font'>Revenir au top</a>
        <div className={"container"}>
          <div className={'row'}>
            <div className="col s12 m4">
              <h3 className=" footer-title">Nom de site</h3>
              <p style={{fontSize: '.75rem'}}>
                Chez Aalladin, Nous sommes ravis lorsque vous l'êtes aussi ! Notre site web comprend la vente enligne qui regroupe les différents produits dont vous aurez besoin au quotidien, Nous travaillons 24/7 pour vous présenter et proposer sans cesse les nouveautés des produits dans toutes les catégories à des prix raisonnable.
                Nous faisons tout notre possible pour vous aider à trouver ce qui vous convient, et de notre mieux pour rendre votre expérience avec nous aussi fluide que possible.
              </p>
            </div>
            <div className="col s12 m4">
              <h3 className={' footer-title'}>Nos Services</h3>
              <nav>
                <ul className='ml0'>
                  <li>
                    <Link to='/' className="typography-font">Page D'accueil</Link>
                  </li>
                  <li>
                    <Link to='/nouveax-produits' className="typography-font">Nouveax Produits</Link>
                  </li>
                  <li>
                    <Link to='/categories-list' className="typography-font">Categories</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col s12 m4">
              <h3 className=" footer-title">Contact &amp; Medias</h3>
              <nav>
                <ul  className='ml0'>
                  <li>
                    <a href="mailto://alg-works@outlook.fr">Par Mail</a>
                  </li>
                  <li>
                    <a href="tel://+213557841237">Par Telephone</a>
                  </li>
                  <li>
                    <a href="https://facebook.com/aalladine" target='_blank' rel="noopener noreferrer">Sur Facebook</a>
                  </li>
                  <li>
                    <a href="messenger.com/pagename" target='_blank' rel="noopener noreferrer">Sur Messenger</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="copyright center">
          Copyright ©2019 Aalladine.com Tous Les Droits Reservés.
          <div className="powred-by">
            Powred by <a href="https://dayenio.ml" rel="noopener noreferrer" target='_blank'>DayenIO</a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
