import axios from 'axios';

const initHeaders = {"content-type": "application/json"};
/**
 * post an ajax request to server
 * @param {String} url backend url
 * @param {Object} details the details to send via post request
 * @param {Object} headers request headers
 */
const post = (url, details, headers=initHeaders) => axios.post(url, details, { headers }).then(res => {
  return res.data;
});

/**
 * get request via fetch browser api
 * @param url path of backend api
 * @param headers request headers
 */
const get = (url, headers=initHeaders) => axios.get(url, { headers }).then(res => res.data);

const api = { post, get, delete: post, put: post };

export default api;
