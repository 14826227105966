// import package installe
import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faHeart,
  faShoppingCart,
  faShoppingBag,
  faList
} from '@fortawesome/free-solid-svg-icons'
// import context
import { MobileNavBarContext, mobileNavBarDefault } from "../store/context";
import { getProductsInsideBasket } from '../utils/actions/product'
// import other Components
import RightMenu from './header/RightMenu';
import SearchForm from './header/SearchForm';
import BurgerMenuIcon from './header/BurgerMenuIcon';
import { Container } from './layout/grid'
import { ListMenu } from './home/MainCategoriesMenu';
// import CSS files
import '../assets/css/header.css'
import '../assets/css/shared.css'
import brandLogo from '../assets/images/logo.png'

class Header extends React.Component {

  state = {
    openMobileSidebarMenu: mobileNavBarDefault.sidebarIsOpen,
    numberOfProductInBasket: mobileNavBarDefault.numberOfProductInBasket,
  }

  /**
   * @name setProductNumberInBasket
   * change the number of product in basket
   */
  setProductNumberInBasket = numberOfProductInBasket => this.setState({ numberOfProductInBasket })

  /**
   * @name onClick
   * fired when costumer click a burger button
   * to display a sidebar menu for mobile version
   */
  onClick = e => {
    e.preventDefault();
    this.setState(prevState => ({
      openMobileSidebarMenu: !prevState.openMobileSidebarMenu,
    }));
  }

  componentDidMount() {
    let numberOfProductInBasket = getProductsInsideBasket().length;
    this.setProductNumberInBasket(numberOfProductInBasket);
  }

  render() {
    let { categories, location } = this.props;
    const mainCategories = categories.edges
      .map(({ node: category }) => category)
      .filter(category => category.display === 'products');
    const path = location && location.pathname ? location.pathname : '';

    return (
      <nav id="main-menu" className={`${/\/produit\/.+/.test(path || '') ? 'fade' : ''}`}>
        <div className='container tels-block'>
          <a href="tel:+213557841237" className="pl0">05 57 84 12 37</a>&nbsp;
          <a href="tel:+213799599125" className='pr0 right-on-small-device'>07 99 59 91 25</a>
        </div>
        <div className={"fixed-menu js-top-fixed"}>
          {/* fixed-menu__top */}
          <div className="container nav-menu ">
            <BurgerMenuIcon onClick={this.onClick} />
            <Link to='/' className="brand-logo left item pl0">
              <img src={brandLogo} alt="aalladine brand logo" />
            </Link>
            <RightMenu numberOfProductInBasket={this.state.numberOfProductInBasket} />
            <SearchForm
              navigate={this.props.navigate}
              searchKey={this.props.searchKey || ''}
              categories={categories}
              onChangeSearchKey={this.props.onChangeSearchKey || null}
              onChange={this.props.onChange}
            />
            <nav className={`mobile-sidebar-menu ${this.state.openMobileSidebarMenu ? 'open' : ''}`}>
              <ul className="mobile-sidebar-menu__container">
                <li className='mobile-sidebar-menu__item mobile-sidebar-menu__header' >
                  <Link to='/' className="mobile-sidebar-menu__link">
                    <img src={brandLogo} alt="aalladine brand logo" />
                  </Link>
                </li>
                <li className='mobile-sidebar-menu__item'>
                  <Link to={`/`} className='mobile-sidebar-menu__link capitalize' title="Vers la page d'accueil">
                    <FontAwesomeIcon icon={faHome} />&nbsp;
                    Accueil
                  </Link>
                </li>
                <li className='mobile-sidebar-menu__item'>
                  <Link to={`/panier`} className='mobile-sidebar-menu__link capitalize' title="Vers le panier">
                    <FontAwesomeIcon icon={faShoppingCart} />&nbsp;
                    Panier
                  </Link>
                </li>
                <li className='mobile-sidebar-menu__item'>
                  <Link to={`/favorite-products`} className='mobile-sidebar-menu__link capitalize' title="Page des produits aimées">
                    <FontAwesomeIcon icon={faHeart} />&nbsp;
                    Produits aimées
                  </Link>
                </li>
                <li className='mobile-sidebar-menu__item'>
                  <Link to={`/nouveax-produits/`} className='mobile-sidebar-menu__link capitalize'>
                    <FontAwesomeIcon icon={faShoppingBag} />&nbsp;
                    Nouveaux Produits
                  </Link>
                </li>
                {
                  mainCategories &&
                  <ListMenu
                    itemClassName='mobile-sidebar-menu__item'
                    linkClassName='mobile-sidebar-menu__link capitalize'
                    categories={mainCategories} />
                }
              </ul>
            </nav>
            <div className={`mobile-sidebar-wrapper ${this.state.openMobileSidebarMenu ? 'show' : 'hide'}`} onClick={this.onClick}></div>
          </div>
        </div>
        <div className="fake-div" />
        <Container style={{ backgroundColor: '#f30240' }}>
          <ul className="nav-submenu row center">
            <li className={`${location && location.pathname === "/" ? "active" : ""} col pl0 s4 m3`}>
              <Link to="/categories-list/">
                <FontAwesomeIcon icon={faList} /> Catégories</Link>
            </li>
            <li className="col s4 m3 pr0">
              <Link to='/nouveax-produits'>
                <FontAwesomeIcon icon={faShoppingBag} />&nbsp;Nouveaux
              </Link>
            </li>
            <li className="col s4 m3 pr0">
              <Link to='/'>
                <FontAwesomeIcon icon={faHome} />&nbsp;Accueil
              </Link>
            </li>
          </ul>
        </Container>
      </nav>
    )
  }

}

Header.contextType = MobileNavBarContext;

export default Header;
